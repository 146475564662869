import { useQuery, useQueryClient } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { Post } from "@circle-react/types/Post";

export const REQUEST_KEY = "post-summary";

interface UsePostSummaryProps {
  post: Post;
}

interface AiSummary {
  content: string;
  overview?: string;
}

export const usePostSummary = ({ post }: UsePostSummaryProps) => {
  const queryKey = [REQUEST_KEY, post.id];
  const queryClient = useQueryClient();
  const { data, isLoading, refetch } = useQuery<AiSummary>(
    queryKey,
    () =>
      reactQueryGet(
        internalApi.posts.summary({
          postId: String(post.id),
        }),
      ),
    {
      enabled: false, // Don't auto-fetch when mounted
      staleTime: 10 * 60 * 1000, // 10 minutes
      retry: (_, error: any) => error?.status !== 404,
    },
  );

  const reset = () => {
    if (isLoading) {
      void queryClient.cancelQueries(queryKey);
    }
    void queryClient.resetQueries({ queryKey, exact: true });
  };

  const toggleSummary = () => {
    if (data || isLoading) {
      void reset();
    } else {
      void refetch();
    }
  };

  return {
    summary: data,
    isLoadingSummary: isLoading,
    refetchSummary: refetch,
    clearSummary: reset,
    toggleSummary,
  };
};

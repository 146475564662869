import { EventEmitter } from "events";

declare interface ChatEventsEmitter {
  on(event: "onBookmarkToggle", listener: (messageId: number) => any): this;
  on(
    event: "sendSuggestedQuestion",
    listener: (payload: { body: object; pause_ai_minutes?: number }) => any,
  ): this;
}

class ChatEventsEmitter extends EventEmitter {
  constructor() {
    super();
  }

  toggleMessageBookmark = (messageId: number) => {
    this.emit("onBookmarkToggle", messageId);
  };

  sendSuggestedQuestion = (payload: {
    body: object;
    pause_ai_minutes?: number;
  }) => {
    this.emit("sendSuggestedQuestion", payload);
  };
}

export const chatEventsEmitter = new ChatEventsEmitter();

import { useCallback, useEffect, useRef, useState } from "react";
import { t } from "@/i18n-js/instance";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { usePostSummary } from "@circle-react/hooks/posts/usePostSummary";
import type { Post } from "@circle-react/types/Post";
import { AiSummary } from "@circle-react-shared/AiSummary";
import { Typography } from "@circle-react-uikit/Typography";

interface PostSummaryProps {
  post: Post;
  className?: string;
  shouldScrollIntoView?: boolean;
}

const LOCALE_ROOT = "post.summary";

export const PostSummary = ({
  post,
  className,
  shouldScrollIntoView = false,
}: PostSummaryProps) => {
  const { summary, isLoadingSummary, clearSummary } = usePostSummary({ post });
  const summaryRef = useRef<HTMLDivElement>(null);
  const [isExpanding, setIsExpanding] = useState(false);
  const isVisible = Boolean(summary) || isLoadingSummary;
  const isExpandCompleted = !isExpanding && Boolean(summary);

  useEffect(() => {
    if (isLoadingSummary) {
      setIsExpanding(true);
    }
  }, [isLoadingSummary]);

  useEffect(() => {
    if (shouldScrollIntoView && summaryRef.current && isVisible) {
      summaryRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isVisible, shouldScrollIntoView]);

  const handleCloseClick = () => {
    setIsExpanding(false);
    clearSummary();
  };

  const handleExpandComplete = useCallback(() => {
    setIsExpanding(false);
  }, []);

  if (!post.has_summary) {
    return null;
  }

  return (
    <div
      className={classNames(
        "bg-ai-summary flex px-5 transition-all duration-500 ease-in-out md:px-6",
        {
          "h-0 py-0 opacity-0 md:py-0": !isVisible,
          "h-auto py-4 opacity-100": isVisible,
        },
        className,
      )}
      ref={summaryRef}
    >
      {isVisible && (
        <AiSummary onCollapseClick={handleCloseClick}>
          <AiSummary.Content className="gap-4">
            <AiSummary.Header
              animate={isExpanding}
              isHidden={isExpandCompleted}
            >
              {t([LOCALE_ROOT, "loading"])}
            </AiSummary.Header>
            <div className="flex flex-col gap-1">
              <Typography.LabelSm color="text-default" leading="leading-tight">
                {t([LOCALE_ROOT, "title"])}
              </Typography.LabelSm>
              <Typography.TitleMd>{post.name}</Typography.TitleMd>
            </div>
            {summary?.content && (
              <AiSummary.BodyText
                animation="fade-in"
                onAnimationComplete={handleExpandComplete}
              >
                {summary.content}
              </AiSummary.BodyText>
            )}
          </AiSummary.Content>
        </AiSummary>
      )}
    </div>
  );
};
